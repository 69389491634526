<template>
  <section>
    <b-row>
      <b-col md="12">
        <div class="d-flex flex-row">
          <div>Pending</div>
          <b-form-checkbox
            v-model="paginationData.approved"
            class="custom-control-primary ml-1"
            name="switch-approved"
            :disabled="loading"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="XIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="CheckIcon" />
            </span>
          </b-form-checkbox>
          Approved
        </div>

      </b-col>
      <b-col
        v-if="loading"
        md="12"
        class="mt-1"
      >
        <div class="text-center">
          <b-spinner
            variant="primary"
            label="Please wait..."
          />
        </div>
      </b-col>
      <b-col md="12">
        <small>Total No of Records: {{ totalRecords }}</small>
      </b-col>
      <b-col
        v-if="!loading && totalRecords===0"
        md="12"
        class="text-center"
      >
        <small>No {{ (paginationData.approved) ? 'Approved' : 'Pending' }} Artwork Found</small>
      </b-col>
      <b-col
        v-for="artwork in allArtworks"
        :key="artwork.id"
        md="4"
      >
        <Artwork
          :artwork="artwork"
          @fetch-artwork="fetchAllArtworkOfCreator"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
            :value="paginationData.page + 1"
            align="center"
            :total-rows="totalRecords"
            :per-page="paginationData.page_size"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            @change="pageChanged"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import {
  BRow, BCol,
  BPagination,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
import { getAllArtworkOfCreator } from '@/api/artWork'
import Artwork from '../../ArtWork/ArtWork.vue'

export default {
  name: 'UserArtwork',
  components: {
    BRow,
    BCol,
    BPagination,
    BSpinner,
    BFormCheckbox,
    Artwork,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  setup(props) {
    const loading = ref(false)
    const allArtworks = ref([])
    const paginationData = ref({
      page: 0,
      page_size: 6,
      approved: false,
    })
    const totalRecords = ref(0)

    const fetchAllArtworkOfCreator = async () => {
      loading.value = true
      const payload = new FormData()
      payload.append('page', paginationData.value.page)
      payload.append('page_size', paginationData.value.page_size)
      payload.append('approved', paginationData.value.approved)
      const { data } = await getAllArtworkOfCreator(props.userId, payload)
      allArtworks.value = data.content
      totalRecords.value = data.totalElements
      loading.value = false
    }
    const pageChanged = value => {
      paginationData.value.page = value - 1
    }

    // Watch
    watch(paginationData.value, fetchAllArtworkOfCreator)
    onMounted(async () => {
      await fetchAllArtworkOfCreator()
    })

    return {
      allArtworks,
      paginationData,
      totalRecords,
      loading,

      fetchAllArtworkOfCreator,
      pageChanged,
    }
  },
}
</script>

<style scoped>

</style>
