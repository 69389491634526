<template>
  <section>
    <b-col
      class="mt-3"
    >
      <b-card
        tag="article"
        style="height: 100% !important"
        no-body
      >
        <b-img
          height="350"
          :src="artwork.waterMarkedImagePath"
          :alt="artwork.waterMarkedImagePath.slice(5)"
          class="card-img-top"
        />

        <b-card-body>
          <b-card-title>
            <b-row>
              <b-col md="9">
                {{ artwork.name }}
                <b-link class="text-body">
                  <feather-icon
                    :id="artwork.id"
                    icon="EditIcon"
                    @click="openModal(artwork.id)"
                  />
                </b-link>

              </b-col>
              <b-col md="3">
                <h6 class="float-right">
                  Price: {{ artwork.price ? artwork.price : artwork.salePrice }}
                </h6>
              </b-col>
            </b-row>
          </b-card-title>
          <b-media no-body>
            <b-media-aside
              vertical-align="center"
              class="mr-50"
            >
              <b-avatar
                href="javascript:void(0)"
                size="24"
                :src="artwork.avatar"
              />
            </b-media-aside>
            <b-media-body>
              <small class="text-muted mr-50">by</small>
              <small>
                <b-link class="text-body">{{
                  artwork.creator.fullName
                }}</b-link>
              </small>
              <span class="text-muted ml-75 mr-50">|</span>
              <small class="text-muted">{{ new Date(artwork.created).toLocaleString() }}</small>
            </b-media-body>
          </b-media>
          <div class="my-1 py-25">
            <b-link
              v-for="(tag, index) in artwork.tags"
              :key="index"
            >
              <b-badge
                pill
                class="mr-75"
                variant="light-primary"
              >
                {{ tag.name }}
              </b-badge>
            </b-link>
          </div>
          <b-card-text class="blog-content-truncate" />
          <hr>
          <div class="d-flex justify-content-between align-items-center">
            <div
              v-b-tooltip.hover.top="kFormatter(artwork.description)"
              class="d-flex align-items-center text-body"
            >
              <span
                class="font-weight-bold"
              >{{ (artwork.description.length>200) ? artwork.description.substring(0, 200) + " ..." : artwork.description }}</span>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <EditArtWork
      ref="editArt"
      @fetch-artwork="fetchArtworks"
    />
  </section>

</template>

<script>
import {
  BCol,
  BCard,
  BCardBody,
  BAvatar,
  BMedia,
  BRow,
  BCardText,
  BBadge,
  BLink,
  BMediaBody,
  BMediaAside,
  BCardTitle,
  BImg,

  VBTooltip,

} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import EditArtWork from './EditArtWork.vue'

export default {
  name: 'ArtWork',
  components: {
    BCol,
    BCard,
    BCardBody,
    BAvatar,
    BMedia,
    BRow,
    BCardText,
    BBadge,
    BLink,
    BMediaBody,
    BMediaAside,
    BCardTitle,
    BImg,
    EditArtWork,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    artwork: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const editArt = ref()

    const openModal = id => {
      editArt.value.toggleModal(id)
    }
    const fetchArtworks = () => {
      context.emit('fetch-artwork')
    }

    return {
      editArt,

      openModal,
      fetchArtworks,

      kFormatter,
    }
  },
}
</script>

<style scoped></style>
