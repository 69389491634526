<template>
  <section>
    <b-col
      v-if="userData===null"
      md="12"
    >
      <div class="text-center">
        <b-spinner
          variant="primary"
          label="Please wait..."
        />
      </div>
    </b-col>
    <span v-if="userData!=null && userData!==''">
      <b-card>
        <b-row>
          <!-- User Info: Left col -->
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
              <b-avatar
                :src="(userData.creators!==null) ? userData.creators.portraitPhoto : ''"
                :text="avatarText(userData.fullName)"
                :variant="`light-${resolveUserRoleVariant(
                  userData.userRoles.role.split('_')[1]
                )}`"
                size="104px"
                rounded
              />
              <div class="d-flex flex-column ml-1">
                <div class="mt-1">
                  <h4 class="mb-0">
                    {{ userData.fullName }}
                  </h4>
                  <span class="card-text">{{ userData.email }}</span>
                </div>
                <div class="d-flex flex-wrap mt-1">
                  <b-button
                    variant="primary"
                    @click="$bvToast.show('coming-soon-toast')"
                  >
                    Edit
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="ml-1"
                    @click="$bvToast.show('coming-soon-toast')"
                  >
                    Delete
                  </b-button>
                </div>
              </div>
            </div>

            <!-- User Stats -->
            <div class="d-flex align-items-center mt-2">
              <div class="d-flex align-items-center mr-2">
                <b-avatar
                  variant="light-primary"
                  rounded
                >
                  <feather-icon
                    icon="TrendingUpIcon"
                    size="18"
                  />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ userData.totalEarning }}
                  </h5>
                  <small>Total Earning</small>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <b-avatar
                  variant="light-success"
                  rounded
                >
                  <feather-icon
                    icon="DollarSignIcon"
                    size="18"
                  />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0">
                    {{ userData.currentBalance }}
                  </h5>
                  <small>Current Balance</small>
                </div>
              </div>
            </div>
          </b-col>

          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Email</span>
                </th>
                <td class="pb-50">
                  {{ userData.email }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Status</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ (userData.active) ? 'Active' : 'Disabled' }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Role</span>
                </th>
                <td class="pb-50 text-capitalize">
                  <b-badge
                    pill
                    :variant="`light-${resolveUserRoleVariant(
                      userData.userRoles.role.split('_')[1]
                    )}`"
                    class="text-capitalize"
                  >
                    {{ userData.userRoles.role.split('_')[1] }}
                  </b-badge>

                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="FlagIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Country</span>
                </th>
                <td class="pb-50">
                  {{ (userData.country) ? userData.country.name : 'N/A' }}
                </td>
              </tr>
              <tr>
                <th>
                  <feather-icon
                    icon="PhoneIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Contact</span>
                </th>
                <td>
                  {{ userData.contactNo }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
      <UserArtwork
        v-if="userData!==null"
        :user-id="userData.id"
      />
    </span>

    <b-alert
      variant="danger"
      :show="userData === ''"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check User List
        for other users.
      </div>
    </b-alert>

    <!-- toast -->
    <b-toast id="coming-soon-toast">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-center mr-1">
          <b-img
            :src="require('../../../assets/images/logo/znapshot-logo-simple.png')"
            class="mr-1"
            height="20"
            alt="Toast image"
          />
          <strong class="mr-auto">Znapshot</strong>
        </div>
      </template>
      <span>Comming Soon :-)</span>
    </b-toast>
  </section>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import {
  BRow, BCol, BCard,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
  BAlert,
  BImg,
} from 'bootstrap-vue'
import router from '@/router'
import { getUserById, resolveUserRoleVariant } from '@/api/users'
// import { getAllArtworkOfCreator } from '@/api/artWork'
// import Artwork from '../../ArtWork/ArtWork.vue'
import UserArtwork from '@/components/Users/ViewUser/UserArtwork.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BButton,
    BSpinner,
    BAlert,
    BImg,

    // Artwork,
    UserArtwork,
    // BLink,

    // Local Components
    // UserViewUserInfoCard,
  },

  setup() {
    const userData = ref(null)
    const userId = ref()
    const allArtworks = ref([])

    // methods
    const fetchUserData = async () => {
      userId.value = router.currentRoute.params.id
      const { data } = await getUserById(router.currentRoute.params.id)
      userData.value = data
    }

    // const fetchAllArtworkOfCreator = async () => {
    //   const { data } = await getAllArtworkOfCreator(userId.value)
    //   allArtworks.value = data.content
    // }
    onMounted(async () => {
      await fetchUserData()
      // await fetchAllArtworkOfCreator()
    })

    return {
      userData,
      userId,
      allArtworks,

      fetchUserData,
      // fetchAllArtworkOfCreator,

      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
