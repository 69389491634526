import { render, staticRenderFns } from "./ArtWork.vue?vue&type=template&id=0c604f09&scoped=true&"
import script from "./ArtWork.vue?vue&type=script&lang=js&"
export * from "./ArtWork.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c604f09",
  null
  
)

export default component.exports